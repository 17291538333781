#root {
  min-height: 100%;
  height: 100%;
}

.link-unstyled {
  color: inherit;
}

.ant-card {
  /*border: none!important;*/
  /*box-shadow: 0 0 10px rgba(0,0,0,.1)!important;*/
}

a .ant-card:hover {
    box-shadow: 0 0 10px rgba(0,0,0,.05)!important;
}

/* Custom Ant Menu */
.ant-menu {
    font-weight: 500;
    font-size: 13px;
    color: rgb(50, 50, 50);
}

h4,
h3,
h1 {
    font-weight: 600!important;
}

.ant-card-head {
    font-weight: 500!important;
}

.shadow-hover:hover {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.16) !important;
    z-index: 3;
}
